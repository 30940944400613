@charset 'utf-8';

.header {
  position: fixed;
  width: 100%;
  height: 80px;
  background: transparent;
  color: var(--whiteColor);
  z-index: 999;

  &__inner {
    position: relative;
    width: inherit;
    height: inherit;
    max-width: 1600px;
    margin: 0 auto;

    &__contentBox {
      background: transparent;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .logoBox {
        // height: 100px;
        .mainLogo {
          font-size: 2rem;
          line-height: 2.2;
          font-weight: 700;

          .header__logo {
            color: #fff;
            text-decoration: none;
          }
        }
      }

      .nav {
        &__list {
          display: flex;
          justify-content: space-between;
          justify-content: space-around;
          align-items: center;
          margin-bottom: 0;

          li {
            margin: 0 2rem;

            .gnbText {
              display: block;
              color: #e8e8e8;
              font-size: 1.3rem;
              font-weight: 700;

              &:hover {
                color: #ffffff;
                transition: color 0.7s;
              }
            }
          }
        }
      }
    }
  }

  &.scrolled {
    height: 80px;
    background: #2348a7f6;
    color: #cccccc;
    transition: background 0.5s;
    z-index: 9999;

    .gnbText:hover {
      color: #ffffff;
      transition: color 0.2s;
    }
  }
}
